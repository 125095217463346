@import '../app/settings.scss';
@import '../mixins/respond';

nav {
    position: sticky;
    top: 0; // where to sticky
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;

    background: white;
    box-shadow: 0 0 15px 3px rgb(#000000, 0.03);
    user-select: none;

    @include respond-to(mobile) {
        position: relative !important;
        height: 80px;
    }

    .inner {
        max-width: none;
        position: relative;
        margin: 0;
        display: flex;
        align-items: center;
        transition: height 0.25s;
        width: 100%;
        height: 100%;
        gap: 16px;

        @include respond-to(mobile) {
            height: 80px;
            min-height: unset !important;
        }

        .spacer {
            flex-grow: 1;
        }

        h2.logo {
            margin: 0;

            a {
                display: block;
                padding: 0 0 0 35px;

                svg {
                    height: 25px;

                    // english logo
                    &.familynet {
                        position: relative;
                        height: 32px;
                        top: 2px;
                    }

                    @include respond-to(mobile) {
                        height: 22px;
                        padding: 0 0 0 20px;

                        // english logo
                        &.familynet {
                            height: 25px;
                        }
                    }
                }

                @include respond-to(mobile) {
                    padding: 0;
                }
            }
        }
    }

    .menu button,
    .role {
        height: var(--nav-height);
        box-sizing: border-box;
        background: rgba($brand, 0.1);
        padding: 0 25px;
        color: $brand;
        font-size: 16px;
        font-weight: $medium;

        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        figure {
            width: 40px;
            height: 40px;
            min-width: 40px;
            margin-right: 20px;
            background-color: #dbeddb;

            &.no-image {
                background: white;
                border-color: #dbeddb;
            }
        }

        svg {
            fill: rgba($brand, 0.8);
            width: 30px;

            &.chevron {
                width: 15px;
                height: 8px;
                margin-right: 15px;
                transform: rotate(0.5turn);
            }
        }

        &:hover {
            text-decoration: none;
            background: rgba($brand, 0.2);
        }

        & > svg:last-child {
            margin-left: 20px;
        }
        @include respond-to(mobile) {
            .text-overflow {
                white-space: unset !important;
            }
        }

    }

    .role {
        margin: 0 auto 0 40px;
        line-height: 16px;

        figure {
            margin-right: 10px;
        }

        @include respond-to(mobile) {
            margin: 0 0 0 auto;
        }
    }

    .menu button {
        padding-left: 40px;
        font-weight: bold;

        @include respond-to(small-max) {
            padding-left: 20px;

            span {
                display: none;
            }
        }
    }

    .dropdown-menu {
        position: absolute;
        top: calc(var(--nav-height) + 20px);
        right: 15px;

        @media (max-width: 992px) {
            top: 80px;
            right: 15px;
        }

        @include respond-to(mobile) {
            top: calc(100% + 5px);
            right: 5px;
            left: auto;
            margin: 0;
        }
    }

    .logo + .menu {
        margin-left: auto;
    }

    // @media (max-width: 992px) {
    //     .menu button,
    //     .role {
    //         height: 75px;
    //     }
    // }

    @include respond-to(mobile) {
        .menu button,
        .role {
            height: 80px;
        }
    }
}

.btn.notifications {
    display: flex;
    position: relative;
    border-radius: 25px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    padding-left: 16px;
    padding-right: 16px;

    svg {
        height: 25px;
        width: 25px;
        margin: auto;
        max-width: unset;

        &:first-child {
            margin: auto;
        }
    }

    .badge {
        position: absolute;
        top: -7px;
        right: -7px;
        border: 2px solid white;
    }
}

@keyframes bellshake {
    0% { transform: rotate(0); }
    1% { transform: rotate(5deg); }
    3% { transform: rotate(-5deg); }
    4% { transform: rotate(4deg); }
    6% { transform: rotate(-4deg); }
    7% { transform: rotate(2deg); }
    8% { transform: rotate(-2deg); }
    9% { transform: rotate(1deg); }
    10%, 100% { transform: rotate(0); }
}

.notifications {
    &.unread,
    &:hover {
        svg {
            animation: bellshake 5s cubic-bezier(.36,.07,.19,.97) both;
            backface-visibility: hidden;
            transform-origin: top right;
        }
    }

    &.unread {
        svg {
            animation-iteration-count: infinite;
        }
    }
}