$whitespace: 40px;

$px-small:   768px - 2 * $whitespace;
$px-medium:  1023px - 2 * $whitespace;
$px-large:   1272px - 2 * $whitespace;

$mq-mobile:  'only screen and (max-width: 767px)';
$mq-small:   'only screen and (min-width: 768px) and (max-width: 1023px)';
$mq-medium:  'only screen and (min-width: 1024px) and (max-width: 1279px)';

@mixin respond-to($media, $px: 0) {
    @if $media == mobile {
        @media #{$mq-mobile} { @content; }
    }
    @if $media == small {
        @media #{$mq-small} { @content; }
    }
    @if $media == small-max {
        @media only screen and (max-width: 1023px) { @content; }
    }
    @if $media == small-min {
        @media only screen and (min-width: 1023px) { @content; }
    }
    @if $media == medium {
        @media #{$mq-medium} { @content; }
    }
    @if $media == medium-max {
        @media only screen and (max-width: 1279px) { @content; }
    }
    @if $media == medium-min {
        @media only screen and (min-width: 1279px) { @content; }
    }
    @if $media == max-width {
        @media only screen and (max-width: $px) { @content; }
    }
    @if $media == min-width {
        @media only screen and (min-width: $px) { @content; }
    }
}
