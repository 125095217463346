@import './mixins/respond';

:root {
    --nav-height: 90px;
    --nav-mobile-height: 80px;

    --main-padding: 40px;

    --gap: 30px;
    --gap-mobile: 20px;

    --brand: #96cb9e;
    --brand-rgb: 150, 203, 158;

    --spot: #e88d8d;
    --spot-rgb: 232, 141, 141;

    --spot-darker: #c06f6f;

    --text: #23272a;
    --text-lighter: #a1aab0;

    --toastify-color-success: var(--brand);
    --toastify-icon-color-success: var(--brand);

    --background-light: #fbf5f9;
}

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');

body {
    color: var(--text);
    line-height: 26px;
    font-size: 16px;
    background-color: #f2f9f3;
    padding: 0;
    margin: 0;
    overflow: auto;
    scroll-behavior: smooth;
}

body,
button,
input,
select,
textarea {
    font-family: Rubik, sans-serif;
}

html,
body {
    height: 100%;
    overflow-x: hidden;
}

html,
html a,
button,
input {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

nav {
    position: sticky;
    top: 0; // where to sticky
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    height: var(--nav-height);

    background: white;
    box-shadow: 0 0 15px 3px rgb(#000000, 0.03);
    padding-top: env(safe-area-inset-top);
}

.wrapper {
    display: flex;
    flex-direction: column;
    background-image: url('../svg/scribble.svg');
    background-repeat: repeat-x;
    background-position: top center;

    @include respond-to(mobile) {
        height: 100%;
        width: 100%;
        overflow: hidden;

        position: sticky;
        top: 0;

        main {
            flex-grow: 1;
            overflow: auto;
            overflow-x: hidden;
        }
    }
}

main {
    padding-top: var(--nav-height);
    padding-bottom: var(--main-padding);
    max-width: 1060px;
    margin: 0 auto;
    position: relative;
    width: calc(100vw - var(--main-padding) * 2);

    .inner {
        min-height: calc(100vh - (var(--nav-height) * 2) - var(--main-padding));
    }

    @include respond-to(mobile) {
        padding: 0;
        width: 100%;

        padding-top: calc(var(--gap-mobile) * 2);
        padding-bottom: calc(var(--gap-mobile) + var(--nav-mobile-height));

        .inner {
            min-height: unset;
            padding-left: var(--gap-mobile);
            padding-right: var(--gap-mobile);
        }
    }
}

label[for] {
    cursor: pointer;
    user-select: none;
}

.familynet-is-loading {
    min-height: calc(100vh - 90px);
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    color: #e88d8d;
    div {
        max-width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    a {
        color: inherit;
    }
}

.loading-ripple {
    position: relative;
    width: 80px;
    height: 80px;
    div {
        display: block;
        position: absolute;
        border: 4px solid rgba(232, 141, 141, 0.33);
        opacity: 1;
        border-radius: 50%;
        animation: ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    div + div {
        animation-delay: -1s;
    }
}
